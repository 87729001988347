/* comic-neue-regular - latin */
@font-face {
    font-family: 'Comic Neue';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/comic-neue-v2-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./assets/fonts/comic-neue-v2-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./assets/fonts/comic-neue-v2-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('./assets/fonts/comic-neue-v2-latin-regular.woff') format('woff'), /* Modern Browsers */ url('./assets/fonts/comic-neue-v2-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('./assets/fonts/comic-neue-v2-latin-regular.svg#ComicNeue') format('svg'); /* Legacy iOS */
}

body {
    font-family: Comic Neue, serif;
}

.material-grid {
    margin: 0;
    width: 100%;
}

.button-normal {
    padding: 10px 20px;
    background-color: #2b6ca3;
    border: none;
    border-radius: 5px;
    margin: 15px 0;
    font-size: 20px;
    color: white;
    font-family: Comic Neue;
}

.button-normal:hover {
    cursor: pointer;
}

.text-standard {
    font-size: 16px;
    line-height: 1.5;
}

.input-label {
    display: block;
    margin-bottom: 0.5em

}

.input-text-normal {
    width: 100%;
    font-size: 20px;
    padding: 10px;
    border: 2px solid lightgrey;
    border-radius: 5px;
    font-family: Comic Neue;
}

.input-select-normal {
    width: calc(100% - 20px);
    font-size: 20px;
    padding: 10px;
    border: 2px solid lightgrey;
    border-radius: 5px;
    font-family: Comic Neue;
}

.input-file-normal {
    width: calc(100% - 20px);
    font-size: 20px;
    padding: 10px;
}

.table-head-element {
    padding: 0.5em;
    text-align: left;
}

.table-row-element {
    padding: 0.5em;
    text-align: left;
}

.table-wide {
    width: 100%;
}


.table-row-button {

}

.error-message {
    width: 100%;
    text-align: center;
    background-color: #FF1F36;
    color: white;
    border-radius: 5px;
    padding: 0.2em;
}

.error-message p {
    font-size: 16px;
}

.div-container-wide {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 1em;
    margin-bottom: 15px;
}

.wrapper-content {
    width: calc(100% - 265px);
    float: left;
    margin-top: 1em;
    margin-left: 260px;
}

.menu-side-container {
    width: 250px;
    float: left;
    margin-right: 15px;
    margin-left: -8px;
    min-height: calc(100vh - 64px);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    position: fixed;
}

.radio {
    width: 100%;
}

.react-player {
    display: flex;
    justify-content: center;
    background-color: #2B333F;
}

@media only screen and (max-width: 940px) {
    .wrapper-content {
        width: 100%;
        margin-left: 0;
    }
}