#emotion-pic {
    max-height: 40em;
    width: 100%;
    object-fit: cover;
}

.emotion {
    background-image: url(../../assets/images/emotionNew.png);
    min-height: calc(100vh - 64px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-left: -8px;
    margin-right: -8px;
}

.emotionSmall {
    width: calc(100% + 16px);
    margin-left: -8px;
    margin-right: -8px;
    display: none;
    margin-top: -8px;
}

.emotionBig {
    margin-right: -8px;
    margin-left: -8px;
    max-height: 100vh;
    width: calc(100% + 16px);
    object-fit: cover;
    object-position: center;

}


@media only screen and (max-width: 560px) {
    .emotion {
        background-image: url(../../assets/images/emotionSmall.jpg);
        height: auto;
        width: 100%;
    }

    .emotionBig {
        display: none;
    }

    .emotionSmall {
        display: block;
    }


}