#navbar {
    overflow: hidden;
    padding: 10px;
    background-color: #221E96;
}

.navbar-button-left {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    float: left;
}

.navbar-button-right {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    float: right;
}


.navbar-text-left {
    color: white;
}

.navbar-button-inside {
    background: transparent;
    border: none;
}


@media only screen and (max-width: 800px) {
    #menuButton {
        display: flex;
    }
}