.text-footer {
    padding: 1em 5em;

}

.text-footer-right {
    padding: 1em 5em;
    text-align: right;
}

.link-footer {
    padding: 1em 5em;
    text-align: center;
    margin-top: 1em;
}

.link-footer a {
    padding: 0 1em;
    text-decoration: none;
    color: white;
}

@media only screen and (max-width: 960px) {
    .text-footer {
        text-align: center;
    }

    .text-footer-right {
        text-align: center;
    }

}