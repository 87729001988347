.sidebar {
    min-width: 250px;
    text-decoration: none;
    list-style-type: none;
    background-color: lightgrey;
    margin-top: 0;
    margin-bottom: 0;
    padding: 12px 0;
    min-height: calc(100vh - 100px);
    flex: 10;
}

.sidebar-link {
    text-decoration: none;
    font-size: 20px;
    display: block;
    padding: 0.3em;
    color: black;
    font-weight: bold;
}

.sidebar-link:hover {
    background-color: gray;
    color: white;
}