.card-element {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    max-width: 100%;
}

.card-image {
    object-fit: cover;
    max-width: 100%;
}

.card-header {
    padding: 0 20px;
}

.card-text {
    padding: 0 20px 10px 20px;
}