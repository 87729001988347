.course-text {
    width: 100%;
}

.course-headline {
    width: 100%;
    font-size: 32px;
}

.course-button-small {
    padding: 4px 8px;
    border: none;
    border-radius: 5px;
    margin: 10px 0;
}

.course-control-zone {
    width: 100%;
}

.course-button-normal {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
}